import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import mime from 'mime';
import * as faceapi from 'face-api.js';
import { StatusCodes } from 'http-status-codes';

import AppPaths from '../../permissions/AppPaths';
import TCampaign from '../../types/TCampaign';
import DrawOptionsFaceApi from '../../types/TDrawOptionsFaceApi';
import CampaignService from '../../services/http/Campaign';
import UnsupportedVideoMimeType from '../../services/exception/UnsupportedVideoMimeType';
import useInterval from '../../utils/useInterval';
import Notification from '../../components/Common/Notification/Notification';
import TimedQuestionPrompt from '../../components/Elements/QuestionPrompt/TimedQuestionPrompt';
import Assets from '../../services/http/Assets';
import { uuidv4 } from 'utils/utils';
import { SocketConnectionContext } from 'providers/socketConnectionProvider';
import { IWsException } from 'services/exception/WSException';
import { ESocketEventsEmit, ESocketEventsOn } from 'types/ESocketEvents';


const DRAW_CONFIGURATION: DrawOptionsFaceApi = {
    drawLines: false,
    drawPoints: true,
    lineWidth: 2,
    pointSize: 1,
    lineColor: '#FE406C',
    pointColor: '#ffff',
};

const TIME_ANIMATION_RECOGNITION = 2000; // if change this value, change the value in the animation off css file: src/styles/app.css in class .scanline
const INTERVAL_FACEAPI_RECOGNITION = 200;
const TIME_RESET_RECOGNITION_WITH_FACEAPI = 2000;


const CHUNK_SIZE_5MB = 5 * 1024 * 1024;
const Campaign = (props: any) => {
    const {t} = useTranslation();
    const campaignCode = props.match.params.code;
    const { socket } = useContext(SocketConnectionContext);

    const [campaign, setCampaign] = useState<Partial<TCampaign>>();
    const [mediaEnd, setMediaEnd] = useState<boolean>(false);
    const [reactionReady, setReactionReady] = useState(false);
    const [showPlayButton, setShowPlayButton] = useState(true);
    const [reactionStream, setReactionStream] = useState<MediaStream | null>(null);
    const [reactionMediaRecorder, setReactionMediaRecorder] = useState<MediaRecorder | null>(null);
    const [reactionCaptureEnded, setReactionCaptureEnded] = useState<boolean>(false);
    const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);
    const [videoReaction, setVideoReaction] = useState<Blob|undefined>(undefined);
    const [timeToReaction, setTimeoutToReaction] = useState<boolean>(false);

    const reactionRef = useRef<HTMLVideoElement>(null!);
    const mediaRef = useRef<HTMLVideoElement>(null!);
    const playRef = useRef<boolean>(false);
    const canvasRef = useRef<HTMLCanvasElement>(null!);
    const recognitionFace = useRef<boolean>(false);

    const initialIndexBuffer = 1;
    const indexDataBuffer = useRef<number>(initialIndexBuffer);
    const process = {
        INIT: 0,
        UPLOAD: 1,
        COMPLETED: 2,
        ABORT: 3,
    };
    const [fileId] = useState(uuidv4());
    const type = 'reaction';
    const mediaPath = useRef<string | null>(null);
    const [error, setError] = useState<null | IWsException>(null);
    const stateProcess = useRef<number | null>(null);

    useEffect(() => {
        const loadModels = async () => {
            await faceapi.nets.tinyFaceDetector.loadFromUri(window.location.origin + '/models/');
            await faceapi.nets.faceLandmark68Net.loadFromUri('https://cdn.jsdelivr.net/npm/@vladmandic/face-api/model/');
        };
        loadModels();
    }, []);

    useEffect(() => {
        const loadCampaign = async () => {
            try {
                setCampaign(
                    await new CampaignService().get(campaignCode)
                );
            }
            catch (e) {
                props.history.push(AppPaths.notFound);
            }
        };
        if (campaign === undefined) {
            loadCampaign().then();
        }
    }, [campaign]);

    useEffect(() => {
        if (!socket) return;

        socket.on(ESocketEventsOn.ERROR, (err) => {
            setError(err);
        });
    }, [socket]);

    useEffect(() => {
        if (error) {
            Notification.displayException(error as any);
            setError(null);
            if (error.statusCode !== StatusCodes.UNAUTHORIZED && stateProcess.current !== null) {
                emitAbortState();
            }
        }
    }, [error]);

    const emitInitState = async () => {
        if (!socket) return;
        socket.emit(ESocketEventsEmit.MULTIPART_UPLOADER, {
            state: process.INIT,
            id: fileId,
            type: type,
            extension: '.' + mime.getExtension(getMimeType()) ?? '.mp4',
        });
        stateProcess.current = process.INIT;

        try {
            await initAccepted();
        }
        catch (error) {
            console.error(error);
        }
    };

    const emitUploadState = (data: ArrayBuffer) => {
        if (!socket) return;
        socket.emit(ESocketEventsEmit.MULTIPART_UPLOADER, {
            state: process.UPLOAD,
            id: fileId,
            dataIndex: indexDataBuffer.current,
            data: data,
        });
        indexDataBuffer.current += 1;
        stateProcess.current = process.UPLOAD;
    };

    const emitCompletedState = async () => {
        if (!socket) return;
        socket.emit(ESocketEventsEmit.MULTIPART_UPLOADER, {
            state: process.COMPLETED,
            id: fileId,
        });
        try {
            await getUrlBySocket();
        }
        catch (error) {
            console.error(error);
        }
        finally {
            stateProcess.current = null;
            indexDataBuffer.current = initialIndexBuffer;
        }
    };

    const emitAbortState = () => {
        if (!socket) return;
        socket.emit(ESocketEventsEmit.MULTIPART_UPLOADER, {
            state: process.ABORT,
            id: fileId,
        });
        stateProcess.current = null;
        indexDataBuffer.current = initialIndexBuffer;
    };


    const initAccepted = async (): Promise<void | null> => {
        if (!socket) return Promise.resolve(null);
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error('Timeout'));
            }, 4000);
            socket.on(ESocketEventsOn.MULTIPART_UPLOADER_INIZIALIZED, () => {
                console.log('Init upload multipart');
                clearTimeout(timeout);
                resolve(undefined);
            });
        });
    };

    const getUrlBySocket = async (): Promise<string | null> => {
        if (!socket) return Promise.resolve(null);
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error('Timeout: URL not received'));
            }, 6000);
            socket.on(ESocketEventsOn.MULTIPART_UPLOADER_COMPLETED, (data) => {
                clearTimeout(timeout);
                mediaPath.current = data.url;
                resolve(data.url);
            });
        });
    };

    const completedUpload = async (): Promise<void | null> => {
        if (!socket) return Promise.resolve(null);
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error('Timeout: URL not received'));
            }, 6000);
            socket.on('etag', (data) => {
                clearTimeout(timeout);
                resolve(emitCompletedState());
            });
        });
    };

    const resetCanvas = () => {
        if (canvasRef && canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
        }
    };
    const drawInCanvasUsingFaceApi = (faces: faceapi.WithFaceLandmarks<{ detection: faceapi.FaceDetection }>[]) => {
        if (canvasRef && canvasRef.current && faces.length > 0) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }

            const resizedDetections = faceapi.resizeResults(faces, { width: canvas.width, height: canvas.height });

            const drawOptions = new faceapi.draw.DrawFaceLandmarksOptions(DRAW_CONFIGURATION);

            resizedDetections.forEach((detection) => {
                const drawLandmarksInstance = new faceapi.draw.DrawFaceLandmarks(detection.landmarks, drawOptions);
                drawLandmarksInstance.draw(canvas);
            });
        }
    };

    const detectAllFacesWithLandmarks = async () => {
        return await faceapi.detectAllFaces(reactionRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
    };

    const realTimeRecognitionFaceToSmallScreens = () => {
        setTimeout(() => {
            recognitionFace.current = true;
            resetCanvas();
        }, TIME_ANIMATION_RECOGNITION);
    };

    const realTimeRecognitionFaceToAllScreens = () => {
        setTimeout(() => {
            let isIntervalStopped = false;
            const intervalId = setInterval(async () => {
                if (isIntervalStopped) return;
                const facesWithLandmarks = await detectAllFacesWithLandmarks();
                if (isIntervalStopped) return;
                drawInCanvasUsingFaceApi(facesWithLandmarks);
            }, INTERVAL_FACEAPI_RECOGNITION);
            setTimeout(() => {
                isIntervalStopped = true;
                clearInterval(intervalId);
                recognitionFace.current = true;
                resetCanvas();
            }, TIME_RESET_RECOGNITION_WITH_FACEAPI);
        }, TIME_ANIMATION_RECOGNITION);
    };

    const realTimeScannerWithFaceApi = async () => {
        const isSM = window.innerWidth <= 640;
        (isSM) ?
            realTimeRecognitionFaceToSmallScreens() :
            realTimeRecognitionFaceToAllScreens();
    };

    const detectFaces = async () => {
        try {
            if (reactionStream === null && reactionRef.current && !reactionRef.current.srcObject) {
                const stream = await navigator.mediaDevices.getUserMedia({audio: false, video: true});
                setReactionStream(stream);
                reactionRef.current.srcObject = stream;
            }
            else if (reactionStream !== null && reactionRef.current) {
                const faces = await detectAllFacesWithLandmarks();
                realTimeScannerWithFaceApi();
                return faces.length > 0;
            }
        }
        catch (e) {
            // Notification.display('error', e.message);
            const error = e as Error;
            console.log('error', error.message);
        }
        return false;
    };

    useInterval(async () => {
        const wasFaceDetected = await detectFaces();
        setReactionReady(wasFaceDetected);
    }, !reactionReady ? 500 : null);

    const getMimeType = (): string => {
        const types = [
            'video/mp4;codecs=h264',
            'video/webm;codecs=h264',
            'video/quicktime;codecs=h264',
            'video/mp4',
            'video/webm',
            'video/quicktime',
        ];
        for (const type of types) {
            if (MediaRecorder.isTypeSupported(type)) {
                return type;
            }
        }
        throw new UnsupportedVideoMimeType();
    };

    const getReactionPath = async (): Promise<string> => {
        const formData = new FormData();
        formData.append('file', videoReaction!);
        formData.append('id', uuidv4());
        formData.append('type', 'reaction');

        const reactionPath = await new Assets().createPathReaction(formData);
        return reactionPath;
    };


    const uploadAnswer = async (response: boolean|null, deltaTime: number) => {
        try {
            if (!questionAnswered) setQuestionAnswered(true);
            if (reactionCaptureEnded) {
                const media_path = mediaPath.current ? mediaPath.current : await getReactionPath();
                await new CampaignService().sendReaction(
                    campaign?.id!,
                    uuidv4(),
                    media_path,
                );
                await new CampaignService().sendResponse(
                    campaign?.id!,
                    response,
                    deltaTime * 10,
                );
                props.history.push(
                    AppPaths.thanks.replace(':code', campaignCode),
                );
            }
            else {
                setTimeout(() => uploadAnswer(response, deltaTime), 500);
            }
        }
        catch (err) {
            const error = err as Error;
            Notification.displayException(error);
        }
    };

    useEffect(() => {
        const sendReaction = async () => {
            try {
                const media_path = mediaPath.current ? mediaPath.current : await getReactionPath();
                await new CampaignService().sendReaction(
                    campaign?.id!,
                    uuidv4(),
                    media_path,
                );
            }
            catch (e) {
                Notification.display('error', t('userAlreadyReacted'));
            }
            finally {
                if (campaign?.question === undefined || campaign?.question === null) {
                    props.history.push(
                        AppPaths.thanks.replace(':code', campaignCode),
                    );
                }
            }
        };
        if (reactionCaptureEnded && (campaign?.question === null || campaign?.question === undefined)) {
            sendReaction().then();
        }
    }, [
        reactionCaptureEnded,
    ]);

    const recordReaction = async () => {
        if (!(reactionStream instanceof MediaStream)) return;

        let isInitialUpload = true;
        const mediaRecorder = new MediaRecorder(reactionStream, { mimeType: getMimeType() });
        const chunks: Array<Blob> = [];
        let currentChunkSize = 0;

        mediaRecorder.ondataavailable = async (blob: BlobEvent) => {
            if (blob.data.size > 0) {
                chunks.push(blob.data);
                currentChunkSize += blob.data.size;
                if (currentChunkSize >= CHUNK_SIZE_5MB) {
                    if (isInitialUpload === true) {
                        await emitInitState();
                        isInitialUpload = false;
                    }

                    try {
                        const chunkToUpload = new Blob(chunks, { type: getMimeType() });
                        const arrayBuffer = await chunkToUpload.arrayBuffer();
                        emitUploadState(arrayBuffer);
                    }
                    catch (error) {
                        console.error('Error uploading chunk:', error);
                    }

                    chunks.length = 0;
                    currentChunkSize = 0;
                }
            }
        };

        mediaRecorder.onstop = async () => {
            try {
                const finalChunk = new Blob(chunks, { type: getMimeType() });
                const arrayBuffer = await finalChunk.arrayBuffer();

                if (!isInitialUpload) {
                    try {
                        emitUploadState(arrayBuffer);
                        await completedUpload();
                    }
                    catch (error) {
                        console.error('Error uploading final chunk:', error);
                        emitAbortState();
                    }
                }

                setVideoReaction(finalChunk);
            }
            catch (error) {
                console.error('Error during upload:', error);
            }
            finally {
                setReactionCaptureEnded(true);
            }
        };

        mediaRecorder.start(500);
        setReactionMediaRecorder(mediaRecorder);
    };

    useEffect(() => {
        if (mediaEnd) {
            reactionMediaRecorder?.stop();
        }
    }, [mediaEnd]);

    const startReaction = () => {
        if (reactionReady && recognitionFace.current === true) {
            setTimeoutToReaction(true);
        }
        else {
            Notification.display('error', 'Wait for facial recognition to finish');
        }
    };

    return (
        timeToReaction ? (
            <div>
                {!mediaEnd ?
                    <>
                        {/* Grabbing reaction */}
                        <div>
                            <div className="flex h-screen h-full w-full bg-black justify-center items-center bg-black">
                                {reactionReady && showPlayButton ?
                                    <img
                                        onClick={() => {
                                            setShowPlayButton(false);
                                            recordReaction().then();
                                        }}
                                        src="/img/play.png"
                                        alt="play..."
                                        className="h-2/12 w-2/12 md:h-1/12 md:w-1/12"/> :
                                    null}
                                {reactionReady && !showPlayButton ?
                                    <video
                                        autoPlay
                                        playsInline
                                        src={campaign?.media}
                                        onEnded={() => {
                                            setMediaEnd(true);
                                        }}
                                        preload={'auto'}
                                        className="h-full md:w-full! object-contain"
                                        ref={mediaRef}/> :
                                    null}
                            </div>
                        </div>
                    </> :
                    questionAnswered || campaign?.question === undefined || campaign?.question === null ?
                        <>
                            {/* Uploading reaction */}
                            <div className="h-screen flex flex-col flex-row h-full w-full cover" style={{
                                backgroundImage: 'url("/img/promo_coming.jpg")',
                                backgroundSize: '100% 100%',
                            }}>
                                <span
                                    className="text-white font-josefin font-semibold mt-8 md:mt-16 ml-8 md:ml-16 col-8"
                                    style={{
                                        fontSize: 44,
                                        letterSpacing: -3.83,
                                    }}>
                                Sending your <span className="text-objective-validation">reaction…</span>
                                </span>
                                <img
                                    src="/img/drawing.gif"
                                    alt="loading..."
                                    className="fixed bottom-20 md:bottom-20 right-16 md:right-20 w-3/12 lg:w-2/12"/>
                            </div>
                        </>:
                        <>
                            {/* Question */}
                            <TimedQuestionPrompt
                                question={campaign?.question}
                                onClickNo={(deltaTime) => {
                                    uploadAnswer(false, deltaTime).then();
                                }}
                                onClickYes={(deltaTime) => {
                                    uploadAnswer(true, deltaTime).then();
                                }}
                                onTimeOut={(deltaTime) => {
                                    uploadAnswer(null, deltaTime).then();
                                }}
                            />
                        </>
                }
            </div>
        ) : (
            <div className='flex h-screen w-full bg-black justify-center items-center'>
                <div className=' flex flex-col bg-white p-5 md:p-8 cardCamera'>
                    <div className="relative flex justify-center items-center mb-8 videoCameraContainer">
                        <video
                            autoPlay
                            playsInline
                            muted
                            className="object-cover w-full h-full videoCamera"
                            preload='auto'
                            ref={reactionRef}
                            onPlay={() => playRef.current = true}
                        />
                        {playRef.current && <div className="scanline absolute top-0 left-0 w-full h-full pointer-events-none"></div>}
                        <canvas
                            ref={canvasRef}
                            className="absolute top-0 left-0 w-full h-full pointer-events-none"
                        />
                    </div>

                    <p className='font-montserrat text-xl font-medium tracking-071pt text-darkBlueText mb-4 firtParagraph'>
                        {t('reactionCardParagraph1')} {' '}
                        <span className='font-montserrat text-xl font-bold tracking-071pt text-darkBlueText'>
                            {t('reactionCardSpanParagraph1')}
                        </span>
                    </p>

                    <p className='font-montserrat text-sm font-medium text-midnightBlueText mb-8 secondParagraph'>
                        ({t('reactionCardParagraph2')})</p>
                    <button
                        className="font-montserrat font-semibold text-base bg-startReactionButton uppercase rounded-3xl text-white self-center"
                        style={{width: '220px', height: '44px'}}
                        onClick={startReaction}
                    >
                        {t('reactionCardButton')}
                    </button>
                </div>
            </div>

        )
    );
};

export default Campaign;
