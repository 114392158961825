import React, {Suspense, useEffect, useState} from 'react';
import {Route, RouteProps, Switch} from 'react-router-dom';

import authInterceptor from 'services/AuthInterceptor';

import {SpinnerLoader} from 'components/Common/Loader/Loader';
import Base from 'components/Layout/Base';

import StorageAuth from 'services/storage/Auth';

// Role Related
import {getRoutesByRole, getUnauthorizedRoutes} from './AppPaths';
import MetaMenu, {ISidebarMenuItem} from './MetaMenu';
import PermissionProvider from 'providers/permissionProvider';
import EUserRol from 'types/EUserRol';
import UserProvider from 'providers/userProvider';
import UserService from 'services/http/User';
import TUser from 'types/TUser';
import NotFound from '../pages/NotFound/NotFound';
import { SocketConnectionProvider } from 'providers/socketConnectionProvider';

const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => {
    return <Tag {...props} />;
};

authInterceptor();
const Auth = new StorageAuth();

export default ({location}: RouteProps) => {
    let isLoggedIn = Auth.isTokenAlive(Auth.getSession());
    const [permissionAccess, setPermissionAccess]: string | any = useState([]);
    const [user, setUser]: TUser | any = useState(null);
    let routes: any = [];

    useEffect(() => {
        const handleUser = async () => {
            const token = Auth.getAccessToken();
            if (isLoggedIn && token) {
                try {
                    const response = await new UserService().me();
                    if (response && response.data) {
                        setUser(response.data);
                    }
                }
                catch (err) {
                    if (err.code === 401 || (err.code === 500 && Auth.getSession().accessToken !== null)) {
                        Auth.removeSession();
                        isLoggedIn = false;
                        window.location.reload();
                    }
                }
            }
        };
        handleUser().then();
    }, [permissionAccess]);

    if (!isLoggedIn) {
        routes = getUnauthorizedRoutes();
    }
    else {
        routes = getRoutesByRole([EUserRol.USER]);
    }

    const routesComponents: JSX.Element[] = routes.map((route: any) => (
        <Route
            path={route.path}
            exact
            component={waitFor(route.component)}
            key={route.path}
        />
    ));
    const metaMenuRoutes: ISidebarMenuItem[] = routes.flatMap((route: any) =>
        MetaMenu.filter((menu) => menu.path === route.path)
    );

    const fallback = (
        <div
            className="page-loader d-flex flex-row justify-content-center"
            style={{height: '100vh'}}
        >
            <SpinnerLoader width={'150px'} height={'150px'} color="black"/>
        </div>
    );

    return (
        <PermissionProvider.Provider
            value={[permissionAccess, setPermissionAccess]}
        >
            <UserProvider.Provider value={[user, setUser]}>
                <SocketConnectionProvider>
                    <Base menu={metaMenuRoutes}>
                        <div>
                            <Suspense fallback={fallback}>
                                <Switch location={location}>
                                    {routesComponents}
                                    <Route component={NotFound}/>
                                </Switch>
                            </Suspense>
                        </div>
                    </Base>
                </SocketConnectionProvider>
            </UserProvider.Provider>
        </PermissionProvider.Provider>
    );
};
