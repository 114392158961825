import Axios from 'axios';
import Http from './Http';
import { handleError } from './utils';

export default class Assets extends Http {
    constructor() {
        super('');
    }

    async createPathReaction(body: FormData): Promise<string> {
        try {
            const ret = await Axios.post('/assets/files', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                ...this.requestConfig,
            });
            if (ret) {
                console.log(ret);
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
        return '';
    }
}
